<template>
  <div class="result">
    <Nav title="评估结果"></Nav>
    <div class="c">
      <div class="c-info">
        <div class="info">
          <div class="info-1">
            <div class="avt-c">
              <div class="avt">
                <img src="../../assets/img/old/person.png"
                     alt="">
              </div>
              <div class="name">{{resData.questionnaireInfo.userName}}</div>
            </div>
            <div class="doct">医生：{{resData.questionnaireInfo.doctorName}}</div>
          </div>
          <div class="info-2">
            <div>体检编号：{{resData.questionnaireInfo.olderReportCode}}</div>
            <div>评估时间：{{resData.questionnaireInfo.recordGuideTime}}</div>
          </div>
          <div></div>
        </div>
      </div>

      <div class="c-item">
        <div class="c-title ">
          <img src="../../assets/img/old/result-1.png"
               alt="">
          <div class="title">体质类型</div>
        </div>
        <div class="type">
          <div class="t"
               v-for="(item,key) in resData.physiqueResult"
               :key="key">
            <div class="t-1">
              <div v-for="(temp,i) in item.physiques"
                   :key="i">{{temp.physique_name}}</div>
            </div>
            <div class="t-2">{{item.result}}</div>
          </div>
        </div>
      </div>

      <div class="c-item">
        <div class="c-title">
          <img src="../../assets/img/old/result-2.png"
               alt="">
          <div class="title">通用中医药保健指导</div>
        </div>
        <div class="zhidao">
          轻质调摄、饮食调养、起居调摄、运动保健
          穴位保健
        </div>
      </div>

      <div v-show="query.isDoc !== '1'"
           class="c-item gexing">
        <div class="c-title">
          <img src="../../assets/img/old/result-3.png"
               alt="">
          <div class="title">个性化中医药保健指导</div>
        </div>
        <div class="zhidao">
          {{resData.questionnaireInfo.doctorGuide}}
        </div>
      </div>

      <div v-show="query.isDoc === '1'"
           class="actionView"
           @click="fillContent">
        <div class="title">个性化中医药保健指导</div>
        <div class="btn">
          <div>{{resData.questionnaireInfo.doctorGuide}}</div>
          <img src="../../assets/img/medical/nav-arrow.png"
               alt="">
        </div>
      </div>
      <div v-show="query.isDoc === '1'"
           class="actionView"
           @click="fillName">
        <div class="title">请填写医生姓名</div>
        <div class="btn">
          <div>{{resData.questionnaireInfo.doctorName}}</div>
          <img src="../../assets/img/medical/nav-arrow.png"
               alt="">
        </div>
      </div>

    </div>

    <div v-show="query.isDoc === '1'"
         class="bottom-btn"
         @click="submit()">
      提交指导
    </div>
  </div>
</template>

<script>
import Nav from '@src/components/Nav.vue'
import { apiUrl, http } from "../../config";
export default {
  components: {
    Nav
  },
  name: '',
  computed: {
  },
  data () {
    return {
      query: {},
      resData: { questionnaireInfo: { doctorGuide: '' } }
    };
  },
  computed: {
  },
  mounted () {
    this.query = { ...this.$route.query }
    // this.query = { applyid: "NwmuHOtod9btK9F7WZmuMEOi3bJcFCxP", idcard: "130406198703210334", olderReportCode: '2', isDoc: '0' }
    // this.query = this.$route.query
    this.get()
    // this.$store.commit('setDoctorGuide', 'hello world2')
    // console.log(this.$store.state.result.doctorGuide)
  },
  methods: {
    get () {
      let url = `/ophs/questionnaire/result`
      http.get(url, { params: this.query }).then(res => {
        if (res.code == 200) {
          this.resData = res.data
          this.resData.questionnaireInfo.recordGuideTime = this.resData.questionnaireInfo.recordGuideTime ? this.resData.questionnaireInfo.recordGuideTime.substring(0, 10) : ""
          let guide = this.$store.state.result.doctorGuide
          let name = this.$store.state.result.doctorName
          //缓存中如果有数据直接拿过来赋值 因为有可能是医生进行了编辑
          if (guide.length > 0) {
            this.resData.questionnaireInfo.doctorGuide = guide
          }
          if (name.length > 0) {
            this.resData.questionnaireInfo.doctorName = name
          }
        } else {
          this.$toasted.show(res.msg);
        }
      });
    },
    fillContent () {
      this.$store.commit('removeDoctorGuide')
      this.$router.push({ 'path': '/oldman/fill', query: { text: this.resData.questionnaireInfo.doctorGuide, title: '个性化中医药保健指导', placeholder: '填写个性化中医药保健指导', commit: 'setDoctorGuide' } })
    },
    fillName () {
      this.$store.commit('removeDoctorName')
      this.$router.push({ 'path': '/oldman/fill', query: { text: this.resData.questionnaireInfo.doctorName, title: '医生姓名', placeholder: '请填写医生姓名', commit: 'setDoctorName' } })
    },
    submit () {
      let data = { ...this.query, ...{ doctorName: this.resData.questionnaireInfo.doctorName, doctorGuide: this.resData.questionnaireInfo.doctorGuide } }
      let url = `/ophs/questionnaire/result/guide`
      http.post(url, data).then(res => {
        this.$toasted.show(res.msg);
        if (res.code == "200") {
          this.query.isDoc = "0"
          this.$router.push({ 'path': '/oldman/result', query: this.query })
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  background: rgba(249, 249, 249, 1);
  min-height: 100%;
  .c {
    padding-bottom: 1.28rem;
    .c-info {
      width: 7.5rem;
      height: 2.6rem;
      padding-top: 1.28rem;
      background: rgba(255, 255, 255, 1);
      .info {
        width: 6.1rem;
        height: 1.8rem;
        background: rgba(0, 198, 174, 1);
        box-shadow: 0px 0px 8px 0px rgba(238, 238, 238, 1);
        border-radius: 10px;
        margin: 0.4rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.3rem;
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.info-1 {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            padding-bottom: 0.2rem;
            .avt-c {
              display: flex;
              font-size: 0.36rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              .avt img {
                width: 0.6rem;
                height: 0.6rem;
                margin-right: 0.3rem;
              }
            }
          }
          &.info-2 {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    .c-item {
      width: 6.7rem;
      min-height: 2rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 8px 0px rgba(238, 238, 238, 0.5);
      border-radius: 10px;
      margin: 0.24rem auto 0.08rem auto;
      text-align: left;
      .c-title {
        padding: 0.3rem 0.2rem 0 0.2rem;
        display: flex;
        align-items: center;
        img {
          width: 0.3rem;
          height: 0.36rem;
          margin-left: 0.1rem;
          margin-right: 0.16rem;
        }
        .title {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(56, 72, 92, 1);
        }
      }
      .type {
        .t {
          display: flex;
          justify-content: space-between;
          margin: 0.1rem 0.4rem 0.2rem 0.4rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          .t-1 {
            display: flex;
            flex-wrap: wrap;
            font-size: 0.28rem;
            color: rgba(56, 72, 92, 1);
            & > div {
              padding: 0 0.1rem;
            }
          }
          .t-2 {
            width: 1rem;
            text-align: right;
            font-size: 0.28rem;
            color: rgba(136, 149, 164, 1);
          }
        }
      }
      .zhidao {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(136, 149, 164, 1);
        margin: 0.24rem 0.42rem 0.3rem 0.78rem;
      }
    }
    .gexing {
      margin-bottom: 0.24rem;
    }
    .actionView {
      height: 1.12rem;
      background: rgba(255, 255, 255, 1);
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(56, 72, 92, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.4rem;
      margin-bottom: 0.01rem;
      > .btn {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 0.14rem;
          height: 0.28rem;
          transform: rotate(180deg);
          margin-left: 0.2rem;
        }
      }
    }
  }
  .bottom-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.98rem;
    background: rgba(0, 198, 174, 1);
    border-radius: 4px;
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.98rem;
  }
}
</style>
