<template>
  <div class="questions">
    <Nav title="评估问卷"></Nav>
    <div class="c">
      <div class="notice">请根据近一年的体验和感觉，回答以下问题</div>
      <div @click="RANDOMCHOOSEACTION">RANDOM CHOOSE</div>
      <div v-for="(question,key) in questions"
           :class="['question', !question.answer_id && clicked ? 'unseleted' : '']"
           :key="key">
        <h5 class="title">{{key+1}}、{{question.question_text}}</h5>
        <div class="chooses">
          <div v-for="(answer,key) in question.answers"
               :key="key"
               :class="answer.id === question.answer_id ? 'check-selected' : 'check'"
               @click="chooseAction(question,answer)">{{answer.answer_name}}</div>
        </div>
      </div>
    </div>
    <div class="bottom-btn"
         @click="submit()">
      提交
    </div>
  </div>
</template>
<script>
import Nav from '@src/components/Nav.vue'
import { apiUrl, http } from "../../config";
export default {
  components: {
    Nav
  },
  name: '',
  data () {
    return {
      clicked: false,
      questions: [],
      query: {}
    };
  },
  mounted () {
    this.get()
  },
  methods: {
    get () {
      let url = `/ophs/questionnaire`
      this.query = this.$route.query;
      http.get(url, { params: this.query }).then(res => {
        if (res.code == 200) {
          this.questions = res.data
        } else {
          this.$toasted.show(res.msg);
        }
      });
    },
    chooseAction (question, answer) {
      question.answer_id = answer.id
      question.answer_value = answer.answer_value
      console.log(question)
      console.log(answer)
    },
    RANDOMCHOOSEACTION () {
      this.questions.forEach((question, index, array) => {
        let i = Math.ceil(Math.random() * 3);
        let answer = question.answers[i]
        this.chooseAction(question, answer)
      })
    },
    submit () {
      this.clicked = true
      let query = this.$route.query;
      let chooseItems = []
      let hasChooseAll = true
      this.questions.forEach((item, index, array) => {
        let question_id = item.id;
        let answer_id = item.answer_id;
        let answer_value = item.answer_value;
        let chooseItem = { question_id, answer_id, answer_value };
        chooseItems.push(chooseItem)
        if (!answer_id) {
          hasChooseAll = false
        }
      })
      if (!hasChooseAll) {
        return
      }
      let items = { items: chooseItems }
      let data = { ...query, ...items, isDoc: "1" };
      console.log(data)

      let url = `/ophs/questionnaire`;
      http.post(url, data)
        .then((res) => {
          // // let id = res.data;
          // // this.answerid = id;
          // this.query.isDoc = "1"
          // this.$router.push({ 'path': '/oldman/result', query: this.query })
          if (res.code == "200") {
            let id = res.data;
            this.answerid = id;
            this.query.isDoc = "1"
            this.$router.push({ 'path': '/oldman/result', query: this.query })
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.questions {
  height: 100%;
  .c {
    margin-top: 1.28rem;
    margin-bottom: 1.28rem;
    .notice {
      position: relative;
      width: 6.7rem;
      height: 0.72rem;
      line-height: 0.72rem;
      background: rgba(254, 252, 235, 1);
      border-radius: 0.2rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(249, 106, 14, 1);
      top: 0.4rem;
      margin: 0.4rem auto 0.4rem auto;
    }
    .question {
      margin: 0.69rem 0.4rem 0 0.4rem;
      &.unseleted {
        border: 1px solid red;
      }
      .title {
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(56, 72, 92, 1);
        text-align: left;
        margin-bottom: 0.3rem;
      }
      .chooses {
        display: flex;
        justify-content: space-between;
        .check {
          background: rgba(239, 239, 239, 1);
          border-radius: 0.25rem;
          padding: 0.02rem;
          width: 56px;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(56, 72, 92, 1);
        }
        .check-selected {
          background: rgba(0, 198, 174, 0.1);
          border-radius: 0.25rem;
          padding: 0.02rem;
          width: 56px;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(0, 198, 174, 1);
        }
      }
    }
  }
  .bottom-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.98rem;
    background: rgba(0, 198, 174, 1);
    border-radius: 4px;
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.98rem;
  }
}
</style>
