<template>
  <div class="nav">
    <i class="arrow"
       @click="$router.back(-1)"></i>
    <h2>{{title}}</h2>
    <div v-show="showRightBtn"
         class="btn"
         @click="rightAction">完成</div>
  </div>
</template>

<script>

export default {
  props: {
    title: '',
    showRightBtn: false
  },
  name: '',
  data () {
    return {

    };
  },
  methods: {
    rightAction () {
      this.$emit('rightAction')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  border: 1px solid rgba(233, 233, 233, 1);
  height: 1.28rem;
  position: fixed;
  background: white;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  .arrow {
    width: 0.19rem;
    height: 0.33rem;
    background: url('../assets/img/medical/nav-arrow.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0.25rem;
    top: 0.58rem;
  }
  h2 {
    font-size: 0.4rem;
    font-weight: 500;
    line-height: 0.56rem;
    margin-top: 0.5rem;
  }
  .btn {
    position: absolute;
    right: 0.25rem;
    top: 0.48rem;
    width: 1.14rem;
    height: 0.64rem;
    line-height: 0.64rem;
    background: rgba(0, 198, 174, 1);
    border-radius: 8px;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
