<template>
  <div class="fill">
    <Nav :title="title"
         :showRightBtn='true'
         @rightAction="archiveAction"></Nav>
    <textarea class="f-area"
              :placeholder="placeholder"
              name=""
              id=""
              v-model="text"></textarea>
  </div>
</template>

<script>
import Nav from '@src/components/Nav.vue'
export default {
  components: {
    Nav
  },
  name: '',
  data () {
    return {
      text: '',
      placeholder: '',
      title: ''
    };
  },
  mounted () {
    let query = this.$route.query
    this.title = query.title
    this.text = query.text
    this.placeholder = query.placeholder
  },
  methods: {
    archiveAction () {
      let query = this.$route.query
      this.$store.commit(query.commit, this.text)
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.fill {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(249, 249, 249, 1);
  .f-area {
    margin-top: 1.4rem;
    min-height: 1.98rem;

    outline-style: none;
    border: 0px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0.4rem 0.4rem;
    font-size: 0.32rem;
  }
  f-area:focus {
    border-color: #66afe9;
    outline: 0;
    font-size: 0.32rem;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}
</style>
